<template>
  <base-page class="about">
    <base-title :level="1">
      Over Volved
    </base-title>
    <base-paragraph>
      Hier kan je straks alles vinden over Volved.
    </base-paragraph>
  </base-page>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>

</style>
